export default [
  {
    id: "#",
    path: "/#",
    text: "Home",
  },
  {
    id: "apps",
    path: "/#apps",
    text: "Apps",
  },
  {
    id: "schafkopf",
    path: "/#schafkopf",
    text: "Schafkopf Abrechner",
  },
]
